import {
  Button,
  Checkbox,
  CircularProgress,
  Container as MuiContainer,
  Divider,
  Grid,
  TextField,
  Typography,
} from '@mui/material';
import fire from 'fire';
import { useCallback, useEffect, useState } from 'react';
import { useRouteMatch } from 'react-router-dom';
import styled from 'styled-components';
import { useSnackbarContext } from 'app/components/ui/general/Layout';
import { Controller, useForm } from 'react-hook-form';

interface OrganizationType {
  name: string;
}

const Organization = () => {
  const {
    params: { id },
  } = useRouteMatch<{ id: string }>();
  const { addSnack } = useSnackbarContext();

  const { handleSubmit, control, reset } = useForm({
    defaultValues: {
      passCode: '',
      instances: 0,
      imageLimit: 0,
      imageRenders: 0,
      videoLimit: 0,
      videoRenders: 0,
      subscriptionActive: false,
    },
  });

  const [data, setData] = useState<OrganizationType>({ name: '' });
  const [loading, setLoading] = useState(false);

  const fetchData = useCallback(async () => {
    setLoading(true);
    const ref = fire.firestore().collection('organizations').doc(id);

    const orgData = (await ref.get()).data();
    setData(orgData as OrganizationType);
    const { subscriptionActive, passCode } = orgData;

    return ref
      .collection('limits')
      .doc('data')
      .get()
      .then(doc => {
        const {
          IMAGE_RENDERS,
          IMAGE_RENDER_LIMIT,
          VIDEO_RENDERS,
          VIDEO_RENDER_LIMIT,
          INSTANCES,
        } = doc.data();

        reset({
          imageLimit: IMAGE_RENDER_LIMIT,
          imageRenders: IMAGE_RENDERS,
          videoLimit: VIDEO_RENDER_LIMIT,
          videoRenders: VIDEO_RENDERS,
          instances: INSTANCES,
          subscriptionActive,
          passCode,
        });
      })
      .finally(() => {
        setLoading(false);
      });
  }, [id, reset]);

  useEffect(() => {
    fetchData();
  }, [id, fetchData]);

  useEffect(() => {}, [id, reset]);

  const save = useCallback(
    async ({
      passCode,
      instances,
      imageLimit,
      imageRenders,
      videoLimit,
      videoRenders,
      subscriptionActive,
    }) => {
      setLoading(true);
      const ref = fire.firestore().collection('organizations').doc(id);
      await ref.update({ subscriptionActive, passCode });

      ref
        .collection('limits')
        .doc('data')
        .set(
          {
            INSTANCES: Number(instances),
            IMAGE_RENDER_LIMIT: Number(imageLimit),
            IMAGE_RENDERS: Number(imageRenders),
            VIDEO_RENDER_LIMIT: Number(videoLimit),
            VIDEO_RENDERS: Number(videoRenders),
          },
          { merge: true },
        )
        .then(() => {
          addSnack({
            message: 'Update successful!',
            severity: 'success',
          });
          fetchData().then(() => {
            setLoading(false);
          });
        })
        .catch(() => {
          addSnack({
            message: 'Failed!',
            severity: 'error',
          });
        });
    },
    [id, addSnack, fetchData],
  );

  return (
    <MuiContainer>
      <Container>
        {loading && (
          <Loader>
            <CircularProgress />
          </Loader>
        )}
        <form onSubmit={handleSubmit(save)}>
          <Grid container spacing={4}>
            <Grid direction="column" spacing={2} item container>
              <Grid item>
                <Typography component="h1" variant="h4">
                  {data.name}
                </Typography>
              </Grid>
              <Controller
                name="subscriptionActive"
                control={control}
                defaultValue={false}
                render={({ field: controlField }) => (
                  <Grid alignItems="center" spacing={2} item container>
                    <Grid item>
                      <Typography variant="button">
                        Subscription active
                      </Typography>
                    </Grid>
                    <Grid item>
                      <Checkbox
                        {...controlField}
                        checked={!!controlField.value || false}
                        edge="start"
                        disableRipple
                      />
                    </Grid>
                  </Grid>
                )}
              />
              <Grid item>
                <Divider variant="fullWidth" />
              </Grid>
            </Grid>
            <Grid xs={6} item container spacing={3} direction="column">
              <Grid item>
                <Typography component="h1" variant="h5">
                  Config
                </Typography>
              </Grid>
              <Grid item>
                <Controller
                  name="passCode"
                  control={control}
                  render={({ field: controlField, fieldState }) => (
                    <TextField
                      {...controlField}
                      error={!!fieldState.error}
                      helperText={fieldState.error?.type}
                      label="Pass code"
                      variant="outlined"
                    />
                  )}
                />
              </Grid>
              <Grid item>
                <Controller
                  name="instances"
                  control={control}
                  render={({ field: controlField, fieldState }) => (
                    <TextField
                      {...controlField}
                      error={!!fieldState.error}
                      helperText={fieldState.error?.type}
                      type="number"
                      label="Instances"
                      variant="outlined"
                    />
                  )}
                />
              </Grid>
            </Grid>
            <Grid item container spacing={10}>
              <Grid xs={6} item container spacing={3} direction="column">
                <Grid item>
                  <Typography component="h1" variant="h5">
                    Limits
                  </Typography>
                </Grid>
                <Grid item>
                  <Controller
                    name="imageLimit"
                    control={control}
                    render={({ field: controlField, fieldState }) => (
                      <TextField
                        {...controlField}
                        error={!!fieldState.error}
                        helperText={fieldState.error?.type}
                        variant="outlined"
                        type="number"
                        label="Image limit"
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    )}
                  />
                </Grid>
                <Grid item>
                  <Controller
                    name="videoLimit"
                    control={control}
                    render={({ field: controlField, fieldState }) => (
                      <TextField
                        {...controlField}
                        error={!!fieldState.error}
                        helperText={fieldState.error?.type}
                        variant="outlined"
                        type="number"
                        label="Video limit"
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    )}
                  />
                </Grid>
              </Grid>
              <Grid xs={6} item container spacing={3} direction="column">
                <Grid item>
                  <Typography component="h1" variant="h5">
                    Renders
                  </Typography>
                </Grid>
                <Grid item>
                  <Controller
                    name="imageRenders"
                    control={control}
                    render={({ field: controlField, fieldState }) => (
                      <TextField
                        {...controlField}
                        error={!!fieldState.error}
                        helperText={fieldState.error?.type}
                        variant="outlined"
                        type="number"
                        label="Image renders"
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    )}
                  />
                </Grid>
                <Grid item>
                  <Controller
                    name="videoRenders"
                    control={control}
                    render={({ field: controlField, fieldState }) => (
                      <TextField
                        {...controlField}
                        error={!!fieldState.error}
                        helperText={fieldState.error?.type}
                        variant="outlined"
                        type="number"
                        label="Video renders"
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    )}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid spacing={3} item container>
              <Grid item>
                <Button
                  disabled={loading}
                  variant="contained"
                  color="primary"
                  type="submit"
                >
                  Save
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </form>
      </Container>
    </MuiContainer>
  );
};

export default Organization;

const Container = styled(MuiContainer)`
  background: ${p => p.theme.secondary};
  padding: 1rem;
  background: ${p => p.theme.secondary};
  box-shadow: 0 5px 20px 0 rgb(0 0 0 / 10%);
  border-radius: 15px;
`;

const Loader = styled.div`
  position: fixed;
  inset: 0;
  display: grid;
  place-items: center;
  background: rgba(255, 255, 255, 0.8);
  z-index: 10;
`;
