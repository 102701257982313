import {
  Accordion,
  AccordionSummary,
  Typography,
  AccordionDetails,
  Grid,
  Box,
  Button,
  TextField,
  Divider,
} from '@mui/material';
import { ExpandMoreSharp } from '@mui/icons-material';
import { Control, Controller, useFieldArray } from 'react-hook-form';
import InnerFields from './InnerFields';
import { FormSchema } from './Template';

interface SectionsProps {
  nestIndex: number;
  control: Control<FormSchema>;
  outerField: {
    copyCount: number;
  };
}

const Sections = ({ nestIndex, control, outerField }: SectionsProps) => {
  const { copyCount } = outerField;
  const rootName = `fields.${nestIndex}.nestedArray` as 'fields.0.nestedArray';
  const { fields, append } = useFieldArray({
    control,
    name: rootName,
    shouldUnregister: true,
  });

  return (
    <Accordion>
      <AccordionSummary expandIcon={<ExpandMoreSharp />}>
        Section {nestIndex + 1}
      </AccordionSummary>
      <AccordionDetails>
        <Grid container spacing={1}>
          <Grid item>
            <Box mr={2} component="div">
              <Typography variant="h5" component="h2">
                Copies
              </Typography>
            </Box>
          </Grid>
          <Grid item container spacing={4}>
            <Grid item xs={4}>
              <Controller
                name={`fields.${nestIndex}.copyCount` as any}
                control={control}
                defaultValue={copyCount || 1}
                render={({ field: controlField, fieldState }) => (
                  <TextField
                    {...controlField}
                    onChange={event =>
                      controlField.onChange(Number(event.target.value))
                    }
                    error={!!fieldState.error}
                    helperText={fieldState.error?.type}
                    fullWidth
                    variant="outlined"
                    label="Amount"
                    type="number"
                  />
                )}
              />
            </Grid>
          </Grid>
          <Grid xs={12} item>
            <Box mt={2} component="div">
              <Typography variant="h5" component="h2">
                Compositions
              </Typography>
            </Box>
          </Grid>
          <Grid xs={12} item container spacing={4}>
            {fields.map((innerField, index) => {
              return (
                <Grid xs={12} item container spacing={4} key={innerField.id}>
                  <InnerFields
                    outerField={innerField}
                    nestIndex={index}
                    {...{ control, rootName }}
                  />
                  <Grid item xs={12}>
                    <Divider variant="fullWidth" />
                  </Grid>
                </Grid>
              );
            })}
          </Grid>
          <Grid item>
            <Button
              type="button"
              size="large"
              variant="outlined"
              color="primary"
              onClick={() =>
                append({
                  compName: '',
                })
              }
            >
              + Composition
            </Button>
          </Grid>
        </Grid>
      </AccordionDetails>
    </Accordion>
  );
};

export default Sections;
