import React, { Suspense } from 'react';
import { Helmet } from 'react-helmet-async';
import { Switch, Route, BrowserRouter } from 'react-router-dom';

import { GlobalStyle } from 'styles/global-styles';

import AuthProvider from 'app/auth/AuthProvider';
import { PrivateRoute, ErrorBoundary } from 'app/components/tools';
import {
  OrganizationsPage,
  LoginPage,
  LogPage,
  TemplatePage,
  DataPage,
  UsersPage,
} from 'app/pages';
import { NotFoundPage } from 'app/pages/NotFoundPage/Loadable';
import { useTranslation } from 'react-i18next';
import { Paths } from 'consts/router';
import { Layout } from './components/ui/general';
import { Organization, Template, User } from './components/ui/resources';

export function App() {
  const { i18n } = useTranslation();
  return (
    <BrowserRouter>
      <Helmet
        titleTemplate="%s - Adflow Admin"
        defaultTitle="Adflow Admin"
        htmlAttributes={{ lang: i18n.language }}
      >
        <meta name="description" content="Adflow Admin Backoffice" />
      </Helmet>

      <ErrorBoundary>
        <AuthProvider>
          <Suspense fallback={<div>Loading...</div>}>
            <Layout>
              <Switch>
                <PrivateRoute exact path={Paths.DATA} component={DataPage} />
                <PrivateRoute exact path={Paths.USERS} component={UsersPage} />
                <PrivateRoute exact path={Paths.USER()} component={User} />
                <PrivateRoute
                  exact
                  path={Paths.ORGANIZATIONS}
                  component={OrganizationsPage}
                />
                <PrivateRoute
                  exact
                  path={Paths.ORGANIZATION()}
                  component={Organization}
                />
                <PrivateRoute
                  exact
                  path={Paths.TEMPLATE()}
                  component={Template}
                />
                <PrivateRoute
                  exact
                  path={Paths.TEMPLATES}
                  component={TemplatePage}
                />
                <PrivateRoute exact path={Paths.LOGS} component={LogPage} />
                <Route exact path={Paths.LOGIN} component={LoginPage} />
                <Route component={NotFoundPage} />
              </Switch>
            </Layout>
          </Suspense>
        </AuthProvider>
      </ErrorBoundary>
      <GlobalStyle />
    </BrowserRouter>
  );
}
