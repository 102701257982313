export const Paths = Object.freeze({
  LOGIN: '/',
  DATA: '/data',
  TEMPLATES: '/templates',
  TEMPLATE: (id?: string) => (id ? `/templates/${id}` : `/templates/:id`),
  ORGANIZATIONS: '/organizations',
  ORGANIZATION: (id?: string) =>
    id ? `/organization/${id}` : `/organization/:id`,
  USERS: '/users',
  USER: (id?: string) => (id ? `/user/${id}` : `/user/:id`),
  LOGS: '/logs',
});
