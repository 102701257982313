/**
 * Create the store with dynamic reducers
 */

import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit';

import { createReducer } from './reducers';

export function configureAppStore() {
  const store = configureStore({
    reducer: createReducer(),
    middleware: [...getDefaultMiddleware()],
    devTools: import.meta.env.NODE_ENV !== 'production',
  });

  return store;
}
