import React, { useEffect } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  List,
  ListItem,
  ListItemText,
} from '@mui/material';
import { useGetDocument } from 'utils/firebase';

const OrganisationPickerModal = ({ handleClose, open, handleSelect }) => {
  const [fetchData, { data }] = useGetDocument();

  useEffect(() => {
    if (open) {
      fetchData('organizations');
    }
  }, [open, fetchData]);

  return (
    <Dialog
      open={open}
      onClose={() => handleClose(false)}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
        Add organization to template
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          This will make this template available for the selected organization
          only.
        </DialogContentText>
        <List>
          {data.map(item => {
            return (
              <ListItem
                onClick={() => handleSelect(item.uid)}
                key={item.uid}
                role={undefined}
                dense
                button
              >
                <ListItemText primary={item.name} secondary={item.uid} />
              </ListItem>
            );
          })}
        </List>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => handleClose(false)} autoFocus>
          CANCEL
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default OrganisationPickerModal;
