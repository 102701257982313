import { useEffect, useRef, useState } from 'react';
import fire from 'fire';
import { getErrorMessage } from './error';
import { useCallback } from 'react';
import { WhereFilterOp } from '@firebase/firestore-types';

export const updateDocument = (userId, documentId, location, objectToSet) => {
  fire
    .firestore()
    .collection(`users/${userId}/${location}`)
    .doc(documentId)
    .update(objectToSet);
};

export const useGetDocument = () => {
  const [data, setData] = useState<any[]>([]);
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(true);

  const mounted = useRef(false);

  useEffect(() => {
    mounted.current = true;

    return () => {
      mounted.current = false;
    };
  }, [mounted]);

  const fetchData = useCallback(
    (
      path: string,
      filter: {
        fieldPath: string;
        filterOperator: WhereFilterOp;
        value: string;
      },
    ) => {
      setLoading(true);
      const collection = fire.firestore().collection(path);

      const collectionToUse = filter
        ? collection.where(
            filter.fieldPath,
            filter.filterOperator || '==',
            filter.value,
          )
        : collection;

      collectionToUse
        .get()
        .then(doc => {
          let items = [] as any;
          doc.forEach(doc => {
            items.push({ ...doc.data(), uid: doc.id });
          });
          if (mounted.current) setData(items);
        })
        .catch(err => {
          if (mounted.current) setError(getErrorMessage(err.code));
        })
        .finally(() => {
          if (mounted.current) setLoading(false);
        });
    },
    [],
  );

  return [fetchData, { data, loading, error }] as [
    Function,
    {
      data: any;
      loading: boolean;
      error: string;
    },
  ];
};

export const useUpdateDocument = ({ location }: { location: string }) => {
  const path = location;
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);

  const update = ({
    objectToSet,
    documentId,
  }: {
    objectToSet: { [x: string]: any };
    documentId: string;
  }) => {
    return new Promise((resolve, reject) => {
      setLoading(true);
      setError('');
      fire
        .firestore()
        .collection(path)
        .doc(documentId)
        .update(objectToSet)
        .catch(err => {
          console.log(err);
          setError(getErrorMessage(err.code));
          reject(err.code);
        })
        .finally(() => {
          setLoading(false);
          resolve('Success');
        });
    });
  };

  return { loading, error, update };
};
