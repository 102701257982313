import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/database';

const firebaseConfig = {
  apiKey: 'AIzaSyDcgYRJjxVwmqBcGzsYGUYbSs3jkwzVgcU',
  authDomain: 'adflow-d9a6d.firebaseapp.com',
  databaseURL:
    'https://adflow-d9a6d-default-rtdb.europe-west1.firebasedatabase.app',
  projectId: 'adflow-d9a6d',
  storageBucket: 'adflow-d9a6d.appspot.com',
  messagingSenderId: '764347150109',
  appId: '1:764347150109:web:4962a3e90ede1a08d57bf4',
  measurementId: 'G-CZTK1KG305',
};

// Initialize Firebase
export default firebase.initializeApp(firebaseConfig);

const auth = firebase.auth();
const provider = new firebase.auth.GoogleAuthProvider();

export const signInWithGoogle = () => {
  return auth.signInWithPopup(provider);
};
