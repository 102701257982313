import { AuthContext } from 'app/auth/AuthProvider';
import { Paths } from 'consts/router';
import React, { FC, useContext } from 'react';

import { Route, Redirect, RouteProps } from 'react-router-dom';

const PrivateRoute: FC<RouteProps> = ({ ...rest }) => {
  const { loggedIn } = useContext(AuthContext);

  if (!loggedIn) {
    return <Redirect to={Paths.LOGIN} />;
  }

  return <Route {...rest} />;
};

export default PrivateRoute;
