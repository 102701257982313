import {
  Container,
  TextField,
  Grid,
  Button,
  Typography,
  Box,
  LinearProgress,
  Chip,
  Checkbox,
} from '@mui/material';
import { useForm, Controller, useFieldArray } from 'react-hook-form';
import fire from 'fire';
import {
  Cancel,
  ExpandLess,
  ExpandMore,
  ViewCarousel,
  FileCopyOutlined,
  CropOriginal,
} from '@mui/icons-material';
import { v4 as uuid } from 'uuid';
import AWS from 'aws-sdk';
import { useRouteMatch, useHistory } from 'react-router-dom';
import { useState, useEffect } from 'react';
import { Paths } from 'consts/router';
import { useSnackbarContext } from 'app/components/ui/general/Layout';
import OrganisationPickerModal from './OrganisationPickerModal';
import Sections from './Sections';
import AutoTemplate from './AutoTemplate';
import { CLOUD_FUNCTION_URL } from 'consts/database';

const s3 = new AWS.S3({
  signatureVersion: 'v4',
  region: 'eu-north-1',
  accessKeyId: import.meta.env.VITE_APP_AWS_ID,
  secretAccessKey: import.meta.env.VITE_APP_AWS_SECRET,
});

export type Field = {
  type: 'data' | 'image';
  inputType:
    | 'data'
    | 'image'
    | 'toggle'
    | 'video'
    | 'textarea'
    | 'section'
    | 'position'
    | 'color';
  layerName: string;
  label: string;
  pageIndex: number;
  property?: string;
  colorList?: string;
  hide?: '' | boolean;
  placeholder?: string;
  toggle?: boolean;
  textarea?: '' | boolean;
};

export interface NestedArray {
  compName: string;
  innerFields: Field[];
}

export interface FormSchema {
  aep: File | null;
  orgId: string | false;
  isImage: boolean;
  meta: {
    updatedAt: number;
    templateName: string;
    preview_url: string;
    description: string;
    duration: string;
    thumbnailTimestamps: number[];
  };
  formats: { ratio: string; target: string }[];
  fields: {
    copyCount: number;
    nestedArray?: NestedArray[];
  }[];
  scraperUrl?: string;
  powerRender?: boolean;
  staticFields: {
    layerName: string;
    src: string;
  }[];
}

const schema = {
  meta: [
    { key: 'templateName', label: 'Template name' },
    { key: 'preview_url', label: 'Preview URL' },
    { key: 'description', label: 'Description' },
    { key: 'duration', label: 'Duration (seconds)' },
    {
      key: 'thumbnailTimestamps',
      label: 'Thumbnail timestamps (comma (,) separated)',
      required: false,
    },
  ],
};

const generateAepFilePath = (id: string) => {
  return `${id}/project.aep`;
};

const Template = () => {
  const { addSnack } = useSnackbarContext();
  const history = useHistory();
  const routerProps = useRouteMatch<{ id: string }>();
  const {
    params: { id },
  } = routerProps;
  const isNew = id === 'new';

  const [currentTemplate, setCurrentTemplate] = useState<any>();
  const [loading, setLoading] = useState(false);
  const [categoryInput, setCategoryInput] = useState('');
  const [categories, setCategories] = useState(['']);

  const defaultTemplate: FormSchema = {
    aep: null,
    orgId: '',
    isImage: false,
    meta: {
      updatedAt: 0,
      templateName: '',
      preview_url: '',
      description: '',
      duration: '',
      thumbnailTimestamps: [],
    },
    fields: [],
    formats: [],
    scraperUrl: '',
    powerRender: false,
    staticFields: [],
  };

  const { meta } = currentTemplate || defaultTemplate;

  const { handleSubmit, control, reset, setValue, watch } =
    useForm<FormSchema>();

  const {
    fields: formatFields,
    append: appendFormat,
    remove: removeFormat,
    swap: swapFormat,
  } = useFieldArray({
    control,
    name: 'formats',
    shouldUnregister: true,
  });

  const {
    fields: staticFields,
    append: appendStaticField,
    remove: removeStaticField,
  } = useFieldArray({
    control,
    name: 'staticFields',
    shouldUnregister: true,
  });

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'fields',
    shouldUnregister: true,
  });

  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSelect = (uid: string) => {
    setValue('orgId', uid);
    setOpen(false);
  };

  useEffect(() => {
    if (!isNew && !loading) {
      fire
        .firestore()
        .collection('templates')
        .doc(id)
        .get()
        .then(doc => {
          const template = doc.data();
          const format: { [ratio: string]: string } = template?.format;
          const staticFields: FormSchema['staticFields'] =
            template?.staticFields;
          setCurrentTemplate({ ...template, uid: doc.id });
          setCategories(template?.categories || ['']);

          reset({
            ...template,
            orgId: template?.orgId || '',
          });

          if (template?.fields) setValue('fields', template?.fields);

          if (staticFields) {
            setValue('staticFields', staticFields);
          }

          if (format) {
            const formats = Object.entries(format).map(item => {
              const [ratio, target] = item;
              return { ratio, target };
            });
            setValue(`formats`, formats);
          }
        });
    }
  }, [id, setValue, isNew, loading, reset]);

  const uploadToAwsS3 = async (
    { templateName, file }: { templateName: string; file: File },
    callback: () => void,
  ) => {
    const response = await fetch(
      `${CLOUD_FUNCTION_URL}/api/misc/upload-template`,
      {
        method: 'POST',
        body: JSON.stringify({ key: templateName }),
        headers: { 'Content-Type': 'application/json' },
      },
    ).then(r => r.json());

    fetch(response.url, {
      method: 'PUT',
      body: file,
    }).then(r => {
      if (r.status !== 200) {
        addSnack({
          message: JSON.stringify(r.text()),
          severity: 'warning',
        });
      } else {
        callback();
      }
    });
  };

  const onSubmit = (values: FormSchema) => {
    setLoading(true);
    const {
      formats,
      aep,
      orgId,
      staticFields: staticItems = [],
      powerRender,
      isImage,
      ...rest
    } = values;
    const format = Object.fromEntries(
      formats.map(item => [item.ratio, item.target]),
    );
    const staticFields = staticItems?.map(item => ({ ...item, type: 'image' }));
    const isPowerRender = isImage && powerRender;

    const thumbnailTimestamps = String(rest.meta.thumbnailTimestamps)
      .split(',')
      .map(n => Number(n));

    const templateRef = (uid?: string) =>
      fire.firestore().collection('templates').doc(uid);

    const baseUpdateParams = {
      ...rest,
      format,
      staticFields,
      orgId: orgId || false,
      meta: {
        ...rest.meta,
        thumbnailTimestamps,
        updatedAt: Date.now(),
      },
      templateSrc: true,
      powerRender: isPowerRender,
      isImage,
    };

    // Existing template being edited
    if (!isNew) {
      templateRef(currentTemplate.uid)
        .update(baseUpdateParams)
        .then(() => {
          addSnack({
            message: 'Update successful!',
            severity: 'success',
          });
          setLoading(false);
        });

      if (aep) {
        // When a project file has been attached, update the file and all fields
        uploadToAwsS3(
          {
            templateName: generateAepFilePath(currentTemplate.id),
            file: aep,
          },
          () => {
            addSnack({
              message: 'Uploaded successfully!',
              severity: 'success',
            });
          },
        );
      }
    }

    // Creating new template
    if (isNew) {
      if (!aep) {
        addSnack({ message: 'Missing .aep file', severity: 'error' });
        return;
      }

      const templateId = uuid();

      // Upload and save metadata
      uploadToAwsS3(
        {
          templateName: generateAepFilePath(templateId),
          file: aep,
        },
        () => {
          const uid = templateRef().id;

          templateRef(uid)
            .set({ ...baseUpdateParams, id: templateId })
            .then(() => {
              addSnack({
                message: 'Successfully uploaded template!',
                severity: 'success',
              });
              setLoading(false);
              history.push(uid);
            });
        },
      );
    }
  };

  const handleCategory = (action: 'update' | 'delete', item: string) => {
    const templateRef = (uid?: string) =>
      fire.firestore().collection('templates').doc(uid);
    const categoryList = categories.filter(Boolean);
    let value = [...categoryList];

    if (action === 'update') {
      value = [...categoryList, categoryInput.toLowerCase()];
    }

    if (action === 'delete') {
      value = categoryList.filter(category => category !== item);
    }

    setCategories(value);
    setCategoryInput('');
    templateRef(currentTemplate.uid).update({
      categories: value,
    });
  };

  const onComplete = (
    compositions: string[],
    layers: { name: string; type: string }[],
  ) => {
    setValue(
      'formats',
      compositions.map(comp => ({ ratio: '', target: comp })),
    );

    append({
      copyCount: 1,
      nestedArray: [
        {
          compName: '',
          innerFields: layers.map(layer => ({
            pageIndex: 1,
            label: layer.name,
            layerName: layer.name,
            type: layer.type === 'IMAGE' ? 'image' : 'data',
            inputType: layer.type === 'IMAGE' ? 'image' : 'data',
          })),
        },
      ],
    });
  };

  if ((!isNew && !currentTemplate) || loading) return <LinearProgress />;

  return (
    <Container onSubmit={handleSubmit(onSubmit)} component="form">
      <Grid direction="column" container spacing={3}>
        <Grid
          container
          item
          xs
          justifyContent="space-between"
          alignItems="center"
        >
          <Grid item>
            <Typography variant="h3">
              {meta.templateName || 'New template'}
            </Typography>
            <small
              onDoubleClick={() => {
                window
                  .getSelection()
                  ?.selectAllChildren(
                    document.getElementById('id') as HTMLElement,
                  );
              }}
              style={{ cursor: 'text' }}
            >
              <span>ID: </span>
              <span id="id">{currentTemplate?.id}</span>
            </small>
          </Grid>
          <Button
            onClick={() => history.push(Paths.TEMPLATES)}
            color="primary"
            variant="contained"
          >
            Back
          </Button>
        </Grid>
        <Grid item spacing={1} container>
          <Grid item>
            <Controller
              rules={{ required: !currentTemplate?.templateSrc }}
              name="aep"
              control={control}
              render={({ field: controlField, fieldState }) => {
                const fileName = controlField?.value?.name;
                return (
                  <Grid container spacing={1} alignItems="center">
                    <Grid item>
                      <Button
                        variant={fileName ? 'text' : 'contained'}
                        color="primary"
                        component="label"
                        endIcon={fileName && <FileCopyOutlined />}
                      >
                        {fileName || 'Upload .aep File'}
                        <input
                          type="file"
                          hidden
                          onChange={event =>
                            controlField.onChange(event.target.files?.[0])
                          }
                        />
                      </Button>
                    </Grid>
                    {fileName && (
                      <Grid item>
                        <Button onClick={() => setValue('aep', null)}>
                          <Cancel />
                        </Button>
                      </Grid>
                    )}
                    {!!fieldState.error && (
                      <Grid xs={12} item>
                        <span style={{ color: 'red', fontWeight: 'bold' }}>
                          Must select a project file
                        </span>
                      </Grid>
                    )}
                  </Grid>
                );
              }}
            />
          </Grid>
          <Grid item>
            <AutoTemplate onComplete={onComplete} />
          </Grid>
          {currentTemplate?.id && (
            <Grid item>
              <Button
                target="_blank"
                rel="noreferrer"
                href={`https://s3.console.aws.amazon.com/s3/buckets/adflow-templates?region=eu-north-1&prefix=${currentTemplate.id}/`}
                variant="outlined"
              >
                Open in S3
              </Button>
            </Grid>
          )}
        </Grid>
        <Grid direction="column" xs item container>
          <Grid item>
            <Controller
              name="isImage"
              control={control}
              defaultValue={!!currentTemplate?.isImage || false}
              render={({ field: controlField }) => (
                <Grid container spacing={1} alignItems="center">
                  <Grid item>
                    <Checkbox
                      {...controlField}
                      checked={!!controlField.value || false}
                      edge="start"
                      tabIndex={-1}
                      disableRipple
                    />
                  </Grid>
                  <Grid item>
                    <Typography variant="subtitle2">JPEG render</Typography>
                  </Grid>
                </Grid>
              )}
            />
          </Grid>
          <Grid item>
            <Controller
              name="powerRender"
              control={control}
              defaultValue={!!currentTemplate?.powerRender}
              render={({ field: controlField }) => (
                <Grid container spacing={1} alignItems="center">
                  <Grid item>
                    <Checkbox
                      {...controlField}
                      checked={
                        (!!watch('isImage') && !!controlField.value) || false
                      }
                      edge="start"
                      tabIndex={-1}
                      disableRipple
                      disabled={!!watch('isImage') === false}
                    />
                  </Grid>
                  <Grid item>
                    <Typography variant="subtitle2">Power render</Typography>
                  </Grid>
                </Grid>
              )}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid spacing={2} container>
        {!isNew && (
          <Grid item xs={12}>
            <Box component="div" mb={2} mt={2}>
              <Typography variant="h6" component="h2">
                Categories / Tags
              </Typography>
            </Box>
            <Grid xs={12} item container spacing={2}>
              <Grid container item xs>
                <TextField
                  fullWidth
                  variant="outlined"
                  label="Tag"
                  value={categoryInput}
                  onChange={event => setCategoryInput(event.target.value)}
                />
                <Button
                  disabled={!categoryInput}
                  onClick={() => handleCategory('update', categoryInput)}
                  color="primary"
                  variant="contained"
                >
                  Add tag
                </Button>
              </Grid>
              <Grid item container spacing={1} xs>
                {categories.filter(Boolean).map(item => (
                  <Grid item key={item}>
                    <Chip
                      label={item}
                      variant="outlined"
                      color="primary"
                      onDelete={() => handleCategory('delete', item)}
                    />
                  </Grid>
                ))}
              </Grid>
            </Grid>
          </Grid>
        )}
        <Grid item xs={12}>
          <Box component="div" mb={2} mt={2}>
            <Typography variant="h6" component="h2">
              Scraper URL
            </Typography>
            <Typography variant="body1">
              Make sure this ends with{' '}
              <Chip
                color="secondary"
                label="&run_now=true&limit=100&source_url="
              />
            </Typography>
          </Box>
          <Controller
            name="scraperUrl"
            control={control}
            defaultValue={currentTemplate?.scraperUrl || ''}
            render={({ field: controlField, fieldState }) => (
              <TextField
                {...controlField}
                error={!!fieldState.error}
                helperText={fieldState.error?.type}
                fullWidth
                variant="outlined"
                label="URL"
              />
            )}
          />
        </Grid>
      </Grid>
      <Grid xs={12} item container>
        <Grid xs={12} item>
          <Box component="div" mb={2} mt={2}>
            <Typography variant="h6" component="h2">
              Formats
            </Typography>
          </Box>
          <Grid xs={12} item container spacing={4}>
            {formatFields.map((field, index) => {
              return (
                <Grid key={field.id} xs={12} item>
                  <Box pb={1}>
                    <Grid container spacing={1}>
                      <Grid item>
                        <ViewCarousel />
                      </Grid>
                    </Grid>
                  </Box>
                  <Grid item xs={12} container spacing={1}>
                    <Grid xs={6} item>
                      <Controller
                        rules={{ required: true }}
                        name={`formats.${index}.ratio`}
                        control={control}
                        defaultValue={field.ratio || '1:1'}
                        render={({ field: controlField, fieldState }) => (
                          <TextField
                            {...controlField}
                            error={!!fieldState.error}
                            helperText={fieldState.error?.type}
                            fullWidth
                            variant="outlined"
                            label="Ratio / Label"
                          />
                        )}
                      />
                    </Grid>
                    <Grid xs={6} item>
                      <Controller
                        rules={{ required: true }}
                        name={`formats.${index}.target`}
                        control={control}
                        defaultValue={field.target || ''}
                        render={({ field: controlField, fieldState }) => (
                          <TextField
                            {...controlField}
                            error={!!fieldState.error}
                            helperText={fieldState.error?.type}
                            fullWidth
                            variant="outlined"
                            label="Target"
                          />
                        )}
                      />
                    </Grid>
                  </Grid>
                  <Box>
                    <Button
                      type="button"
                      size="small"
                      variant="outlined"
                      color="primary"
                      onClick={() => swapFormat(index, index - 1)}
                    >
                      <ExpandLess />
                    </Button>
                    <Button
                      type="button"
                      size="small"
                      variant="outlined"
                      color="primary"
                      onClick={() => swapFormat(index, index + 1)}
                    >
                      <ExpandMore />
                    </Button>
                    <Button
                      type="button"
                      size="small"
                      variant="outlined"
                      color="primary"
                      onClick={() => removeFormat(index)}
                    >
                      <Cancel />
                    </Button>
                  </Box>
                </Grid>
              );
            })}
          </Grid>
          <Box pt={4}>
            <Grid xs={12} item container spacing={2}>
              <Grid item>
                <Button
                  type="button"
                  size="large"
                  variant="outlined"
                  color="primary"
                  onClick={() => appendFormat({ ratio: '1:1' })}
                >
                  + Format
                </Button>
              </Grid>
            </Grid>
          </Box>
        </Grid>
        <Grid xs={12} item>
          <Box component="div" mb={2} mt={2}>
            <Typography variant="h6" component="h2">
              Static fields
            </Typography>
          </Box>
          <Grid xs={12} item container spacing={4}>
            {staticFields.map((field, index) => {
              return (
                <Grid key={field.id} xs={12} item>
                  <Box pb={1}>
                    <Grid container spacing={1}>
                      <Grid item>
                        <CropOriginal />
                      </Grid>
                    </Grid>
                  </Box>
                  <Grid item xs={12} container spacing={1}>
                    <Grid xs item>
                      <Controller
                        rules={{ required: true }}
                        name={`staticFields.${index}.layerName`}
                        control={control}
                        defaultValue=""
                        render={({ field: controlField, fieldState }) => (
                          <TextField
                            {...controlField}
                            error={!!fieldState.error}
                            helperText={fieldState.error?.type}
                            fullWidth
                            variant="outlined"
                            label="Layer name"
                          />
                        )}
                      />
                    </Grid>
                    <Grid xs item>
                      <Controller
                        rules={{ required: true }}
                        name={`staticFields.${index}.src`}
                        control={control}
                        defaultValue=""
                        render={({ field: controlField, fieldState }) => (
                          <TextField
                            {...controlField}
                            error={!!fieldState.error}
                            helperText={fieldState.error?.type}
                            fullWidth
                            variant="outlined"
                            label="Source"
                          />
                        )}
                      />
                    </Grid>
                  </Grid>
                  <Box>
                    <Button
                      type="button"
                      size="small"
                      variant="outlined"
                      color="primary"
                      onClick={() => removeStaticField(index)}
                    >
                      <Cancel />
                    </Button>
                  </Box>
                </Grid>
              );
            })}
          </Grid>
          <Box pt={4}>
            <Grid xs={12} item container spacing={2}>
              <Grid item>
                <Button
                  type="button"
                  size="large"
                  variant="outlined"
                  color="primary"
                  onClick={() => appendStaticField({ layerName: '', src: '' })}
                >
                  + Field
                </Button>
              </Grid>
            </Grid>
          </Box>
        </Grid>
        <Grid xs={6} item>
          <Box component="div" mb={2} mt={2}>
            <Typography variant="h6" component="h2">
              Metadata
            </Typography>
          </Box>
          <Grid xs={12} item container spacing={3}>
            <Grid xs={12} item>
              <Controller
                name="orgId"
                defaultValue={currentTemplate?.orgId || ''}
                control={control}
                render={({ field: controlField, fieldState }) => (
                  <TextField
                    {...controlField}
                    error={!!fieldState.error}
                    helperText={fieldState.error?.type}
                    fullWidth
                    variant="outlined"
                    label="Org UID"
                  />
                )}
              />
              <Button
                color="primary"
                variant="contained"
                onClick={handleClickOpen}
              >
                Select organization
              </Button>
              <Button
                color="secondary"
                variant="contained"
                onClick={() => setValue('orgId', '')}
              >
                Clear
              </Button>
              <OrganisationPickerModal
                {...{ handleClose, handleSelect, open }}
              />
            </Grid>
            {schema.meta.map(item => {
              const { key, label, required } = item;

              return (
                <Grid key={key} xs={12} item>
                  <Controller
                    rules={{ required }}
                    name={`meta.${key}` as any}
                    control={control}
                    defaultValue={meta[key]}
                    render={({ field, fieldState }) => {
                      return (
                        <TextField
                          {...field}
                          error={!!fieldState.error}
                          helperText={fieldState.error?.type}
                          fullWidth
                          variant="outlined"
                          label={label}
                        />
                      );
                    }}
                  />
                </Grid>
              );
            })}
          </Grid>
        </Grid>
      </Grid>
      <Box mt={4} mb={4}>
        <Grid container spacing={2}>
          <Grid item>
            <Button variant="outlined" onClick={() => append({ copyCount: 1 })}>
              + Section
            </Button>
          </Grid>
          {fields.map((section, sectionIndex) => (
            <Grid key={section.id} item xs={12}>
              <Sections
                outerField={section}
                nestIndex={sectionIndex}
                {...{ control, setValue }}
              />
              <Button
                variant="outlined"
                onClick={() =>
                  addSnack({
                    message: 'Double click to delete',
                    severity: 'error',
                  })
                }
                onDoubleClick={() => remove(sectionIndex)}
              >
                DELETE
              </Button>
            </Grid>
          ))}
        </Grid>
      </Box>
      <Box mt={4}>
        <Grid container spacing={2}>
          <Grid item>
            <Button
              type="button"
              size="large"
              variant="contained"
              color="secondary"
              onClick={() => reset(defaultTemplate)}
            >
              Reset
            </Button>
          </Grid>
          <Grid item>
            <Button
              type="submit"
              size="large"
              variant="contained"
              color="primary"
            >
              {currentTemplate ? 'Update' : 'Create'}
            </Button>
          </Grid>
        </Grid>
      </Box>
    </Container>
  );
};

export default Template;
