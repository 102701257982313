import { createGlobalStyle } from 'styled-components';

export const GlobalStyle = createGlobalStyle`
  html,
  body {
    height: 100%;
    width: 100%;
    background-color: #f9f9f9;
  }
  
  * {
    font-family: 'Roboto', 'Helvetica Neue', Helvetica, Arial, sans-serif;
    box-sizing: border-box;
    margin: 0;
    padding: 0;
  }

  h1 {
    font-size: 40px;
    font-weight: normal;
    margin-bottom: 15px;
  }

  #root {
    min-height: 100%;
    min-width: 100%;
  }

  input, select {
    font-family: inherit;
    font-size: inherit;
  }
  pre {
    background: #f1f1f1; 
    padding: 4px; 
    font-family: monospace;
    border-radius: 4px;
  }

  .prevent-scroll {
    overflow: hidden;
    padding-right: 17px;
  }
`;
