import React from 'react';
import styled from 'styled-components';

export default function Loader({
  message,
  isRelative,
}: {
  message?: string;
  isRelative?: boolean;
}) {
  return (
    <Container isRelative={isRelative}>
      <div className="lds-ellipsis">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
      {message && <Message>{message}</Message>}
    </Container>
  );
}

const Message = styled.div`
  position: absolute;
  top: 55%;
  left: 50%;
  color: ${p => p.theme.secondary};
  transform: translate(-50%, -50%);
`;
const Container = styled.div<{ isRelative?: boolean }>`
  position: ${p => (!p.isRelative ? 'fixed' : 'relative')};
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: ${p => (!p.isRelative ? '#000000ad' : 'none')};
  z-index: 9999;

  .lds-ellipsis {
    display: inline-block;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 80px;
    height: 80px;
  }
  .lds-ellipsis div {
    position: absolute;
    top: 33px;
    width: 13px;
    height: 13px;
    border-radius: 50%;
    background: ${p => (p.isRelative ? p.theme.primary : '#fff')};
    animation-timing-function: cubic-bezier(0, 1, 1, 0);
  }
  .lds-ellipsis div:nth-child(1) {
    left: 8px;
    animation: lds-ellipsis1 0.6s infinite;
  }
  .lds-ellipsis div:nth-child(2) {
    left: 8px;
    animation: lds-ellipsis2 0.6s infinite;
  }
  .lds-ellipsis div:nth-child(3) {
    left: 32px;
    animation: lds-ellipsis2 0.6s infinite;
  }
  .lds-ellipsis div:nth-child(4) {
    left: 56px;
    animation: lds-ellipsis3 0.6s infinite;
  }
  @keyframes lds-ellipsis1 {
    0% {
      transform: scale(0);
    }
    100% {
      transform: scale(1);
    }
  }
  @keyframes lds-ellipsis3 {
    0% {
      transform: scale(1);
    }
    100% {
      transform: scale(0);
    }
  }
  @keyframes lds-ellipsis2 {
    0% {
      transform: translate(0, 0);
    }
    100% {
      transform: translate(24px, 0);
    }
  }
`;
