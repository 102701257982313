import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';

import * as React from 'react';
import * as ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
// Import root app
import { App } from './app';

import { HelmetProvider } from 'react-helmet-async';

import { configureAppStore } from './store/configureStore';

// Initialize languages
import './locales/i18n';
import { ThemeProvider } from 'styled-components';
import { theme } from './styles/adflowMainTheme';

const store = configureAppStore();
const MOUNT_NODE = document.getElementById('root') as HTMLElement;

ReactDOM.render(
  <Provider store={store}>
    <HelmetProvider>
      <React.StrictMode>
        <ThemeProvider theme={theme}>
          <App />
        </ThemeProvider>
      </React.StrictMode>
    </HelmetProvider>
  </Provider>,
  MOUNT_NODE,
);
