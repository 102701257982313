import { useState } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  List,
  ListItem,
  ListItemText,
} from '@mui/material';
import { FileCopyOutlined } from '@mui/icons-material';
import { RESOURCE_SERVICE_URL } from 'consts/database';

interface AutoTemplateProps {
  onComplete: (
    compositions: string[],
    layers: { name: string; type: string }[],
  ) => void;
}

const AutoTemplate = ({ onComplete }: AutoTemplateProps) => {
  const [open, setOpen] = useState(false);
  const [data, setData] = useState([]);
  const [step, setStep] = useState(1);
  const [selectedComps, setSelectedComps] = useState([]);
  const [selectedLayers, setSelectedLayers] = useState([]);

  const scanFile = async (file: File) => {
    const project = await file.text();
    const res = await fetch(RESOURCE_SERVICE_URL + '/scan-project', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ project }),
    });

    const json = await res.json();

    setData(json);
    setOpen(true);
  };

  const save = () => {
    onComplete(
      selectedComps,
      selectedLayers.map(layerName => ({
        name: layerName,
        type: data
          .flatMap(comp => comp.layers)
          .find(layer => layer.name === layerName)?.type,
      })),
    );
    setOpen(false);
    setStep(1);
  };

  return (
    <div>
      <Button
        variant="contained"
        color="primary"
        component="label"
        endIcon={<FileCopyOutlined />}
      >
        SCAN TEMPLATE (.AEPX)
        <input
          type="file"
          hidden
          onChange={event => scanFile(event.target.files?.[0])}
        />
      </Button>

      <Dialog
        fullWidth
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {step === 1 && 'Compositions'}
          {step === 2 && 'Layers'}
        </DialogTitle>
        <DialogContent>
          {step === 1 &&
            data.map((comp, index) => {
              return (
                <ListItem
                  selected={selectedComps.includes(comp.compName)}
                  onClick={() =>
                    setSelectedComps(state =>
                      selectedComps.includes(comp.compName)
                        ? state.filter(item => item !== comp.compName)
                        : [...state, comp.compName],
                    )
                  }
                  dense
                  button
                  key={comp?.compName}
                >
                  <ListItemText primary={comp?.compName} />
                </ListItem>
              );
            })}
          {step === 2 && (
            <Grid container item>
              <List style={{ width: '100%' }}>
                {Array.from(
                  new Set(
                    data.flatMap(comp =>
                      comp.layers
                        .filter(layer => layer.type !== 'COMPOSITION')
                        .map(layer => layer.name),
                    ),
                  ),
                ).map(layerName => (
                  <ListItem
                    selected={selectedLayers.includes(layerName)}
                    onClick={() =>
                      setSelectedLayers(state =>
                        selectedLayers.includes(layerName)
                          ? state.filter(item => item !== layerName)
                          : [...state, layerName],
                      )
                    }
                    dense
                    button
                    key={layerName}
                  >
                    <ListItemText
                      primary={layerName}
                      secondary={
                        data
                          .flatMap(comp => comp.layers)
                          .find(layer => layer.name === layerName)?.type ||
                        'unknown'
                      }
                    />
                  </ListItem>
                ))}
              </List>
            </Grid>
          )}
        </DialogContent>
        <DialogActions>
          {step !== 1 && (
            <Button onClick={() => setStep(state => state - 1)}>BACK</Button>
          )}
          {step !== 2 && (
            <Button onClick={() => setStep(state => state + 1)}>NEXT</Button>
          )}
          {step === 2 && <Button onClick={save}>Finish</Button>}
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default AutoTemplate;
