import {
  Grid,
  TextField,
  Typography,
  Button,
  Checkbox,
  Divider,
} from '@mui/material';
import {
  CropOriginal,
  TextFields,
  GridOnOutlined,
  ExpandLess,
  ExpandMore,
  Cancel,
  ColorLens,
} from '@mui/icons-material';
import { Control, Controller, useFieldArray } from 'react-hook-form';
import { FormSchema } from './Template';

interface InnerFieldsProps {
  nestIndex: number;
  control: Control<FormSchema>;
  rootName: 'fields.0.nestedArray';
  outerField: {
    compName: string;
  };
}

const resolveInputTypeFromType = (
  type: 'image' | 'data' | 'position' | 'color',
) => {
  if (['data', 'position', 'color'].includes(type)) {
    return 'data';
  }

  return 'image';
};

const InnerFields = ({
  nestIndex,
  control,
  rootName,
  outerField,
}: InnerFieldsProps) => {
  const { compName } = outerField;
  const innerRootName = `${rootName}.${nestIndex}.innerFields` as const;

  const { fields, append, remove, swap } = useFieldArray({
    control,
    name: innerRootName,
    shouldUnregister: true,
  });

  return (
    <Grid item container spacing={4}>
      <Grid item xs={4}>
        <Controller
          name={`${rootName}.${nestIndex}.compName`}
          control={control}
          defaultValue={compName || ''}
          render={({ field: controlField, fieldState }) => {
            return (
              <TextField
                size="small"
                {...controlField}
                error={!!fieldState.error}
                helperText={fieldState.error?.type}
                fullWidth
                variant="outlined"
                label="Composition name"
              />
            );
          }}
        />
      </Grid>
      <Grid item xs={12}>
        {fields.map((field, index) => {
          const { type, inputType = resolveInputTypeFromType(field.type) } =
            field;

          const hasExtraField = type === 'data';

          return (
            <Grid key={field.id} xs={12} item container>
              <Controller
                rules={{ required: true }}
                name={`${innerRootName}.${index}.type`}
                control={control}
                defaultValue={type}
                render={() => <input type="hidden" />}
              />
              <Controller
                name={`${innerRootName}.${index}.inputType`}
                control={control}
                defaultValue={inputType}
                render={() => <input type="hidden" />}
              />
              <Grid item container xs={10}>
                <Grid style={{ marginBottom: 8 }} xs={12} container spacing={1}>
                  <Grid item>
                    {inputType === 'image' && <CropOriginal />}
                    {inputType === 'data' && <TextFields />}
                    {inputType === 'position' && <GridOnOutlined />}
                    {inputType === 'color' && <ColorLens />}
                  </Grid>
                  <Grid item>
                    <Typography variant="button">{inputType}</Typography>
                  </Grid>
                </Grid>
                <Grid item xs={9} container spacing={1}>
                  <Grid xs={hasExtraField ? 3 : 4} item>
                    <Controller
                      rules={{ required: true }}
                      name={`${innerRootName}.${index}.layerName`}
                      control={control}
                      defaultValue={field.layerName || ''}
                      render={({ field: controlField, fieldState }) => (
                        <TextField
                          size="small"
                          {...controlField}
                          error={!!fieldState.error}
                          helperText={fieldState.error?.type}
                          fullWidth
                          variant="outlined"
                          label="Layer name"
                        />
                      )}
                    />
                  </Grid>
                  <Grid xs={hasExtraField ? 3 : 4} item>
                    <Controller
                      rules={{ required: true }}
                      name={`${innerRootName}.${index}.label`}
                      control={control}
                      defaultValue={field.label || ''}
                      render={({ field: controlField, fieldState }) => (
                        <TextField
                          size="small"
                          {...controlField}
                          error={!!fieldState.error}
                          helperText={fieldState.error?.type}
                          fullWidth
                          variant="outlined"
                          label="Label"
                        />
                      )}
                    />
                  </Grid>
                  <Grid xs={hasExtraField ? 3 : 4} item>
                    <Controller
                      name={`${innerRootName}.${index}.placeholder`}
                      control={control}
                      defaultValue={field.placeholder || ''}
                      render={({ field: controlField, fieldState }) => (
                        <TextField
                          size="small"
                          {...controlField}
                          error={!!fieldState.error}
                          helperText={fieldState.error?.type}
                          fullWidth
                          variant="outlined"
                          label="Placeholder"
                        />
                      )}
                    />
                  </Grid>
                  {type === 'data' && (
                    <Grid xs={hasExtraField ? 3 : 4} item>
                      <Controller
                        rules={{ required: true }}
                        name={`${innerRootName}.${index}.property`}
                        control={control}
                        defaultValue={field.property || 'Source Text'}
                        render={({ field: controlField, fieldState }) => (
                          <TextField
                            size="small"
                            {...controlField}
                            error={!!fieldState.error}
                            helperText={fieldState.error?.type}
                            fullWidth
                            variant="outlined"
                            label="Property"
                          />
                        )}
                      />
                    </Grid>
                  )}
                  {inputType === 'color' && (
                    <Grid xs={12} item>
                      <Controller
                        rules={{ required: true }}
                        name={`${innerRootName}.${index}.colorList`}
                        control={control}
                        defaultValue={field?.colorList}
                        render={({ field: controlField, fieldState }) => (
                          <TextField
                            size="small"
                            {...controlField}
                            error={!!fieldState.error}
                            helperText={fieldState.error?.type}
                            fullWidth
                            variant="outlined"
                            label="Color list"
                            placeholder="Comma separated hex codes, e.g. #f1f1f1,#bababa"
                          />
                        )}
                      />
                    </Grid>
                  )}
                </Grid>
                <Grid item xs={3}>
                  <Button
                    type="button"
                    size="large"
                    variant="outlined"
                    color="primary"
                    disabled={index === 0}
                    onClick={() => swap(index, index - 1)}
                  >
                    <ExpandLess />
                  </Button>
                  <Button
                    type="button"
                    size="large"
                    variant="outlined"
                    color="primary"
                    disabled={index === fields.length - 1}
                    onClick={() => swap(index, index + 1)}
                  >
                    <ExpandMore />
                  </Button>
                  <Button
                    type="button"
                    size="large"
                    variant="outlined"
                    color="primary"
                    onClick={() => remove(index)}
                  >
                    <Cancel />
                  </Button>
                </Grid>
              </Grid>

              <Grid container item xs={2}>
                <Grid direction="column" container>
                  {!['image', 'position', 'color'].includes(inputType) && (
                    <Grid style={{ maxHeight: 24 }} item>
                      <Controller
                        name={`${innerRootName}.${index}.textarea`}
                        control={control}
                        defaultValue={!!field?.textarea}
                        render={({ field: controlField }) => (
                          <>
                            <Checkbox
                              {...controlField}
                              checked={!!controlField.value}
                              edge="start"
                              size="small"
                              tabIndex={-1}
                              disableRipple
                            />
                            <span style={{ marginRight: '1rem' }}>
                              Textarea
                            </span>
                          </>
                        )}
                      />
                    </Grid>
                  )}
                  {!['position', 'color'].includes(inputType) && (
                    <Grid style={{ maxHeight: 24 }} item>
                      <Controller
                        name={`${innerRootName}.${index}.hide`}
                        control={control}
                        defaultValue={field?.hide || ''}
                        render={({ field: controlField }) => (
                          <>
                            <Checkbox
                              {...controlField}
                              checked={!!controlField.value}
                              edge="start"
                              size="small"
                              tabIndex={-1}
                              disableRipple
                            />
                            <span style={{ marginRight: '1rem' }}>
                              Hideable
                            </span>
                          </>
                        )}
                      />
                    </Grid>
                  )}
                  {!['position', 'color'].includes(inputType) && (
                    <Grid style={{ maxHeight: 24 }} item>
                      <Controller
                        name={`${innerRootName}.${index}.toggle`}
                        control={control}
                        defaultValue={!!field?.toggle}
                        render={({ field: controlField }) => (
                          <>
                            <Checkbox
                              {...controlField}
                              checked={!!controlField.value}
                              edge="start"
                              size="small"
                              tabIndex={-1}
                              disableRipple
                            />
                            <span style={{ marginRight: '1rem' }}>Toggle</span>
                          </>
                        )}
                      />
                    </Grid>
                  )}
                </Grid>
              </Grid>
              <Divider style={{ margin: '1rem 0', width: '100%' }} />
            </Grid>
          );
        })}
      </Grid>
      <Grid container item>
        <Grid xs={12} item container spacing={2}>
          <Grid item>
            <Button
              type="button"
              size="large"
              variant="outlined"
              color="primary"
              onClick={() =>
                append({
                  type: 'data',
                  property: 'Source Text',
                  inputType: 'data',
                })
              }
            >
              + Source Text
            </Button>
          </Grid>
          <Grid item>
            <Button
              type="button"
              size="large"
              variant="outlined"
              onClick={() => append({ type: 'image', inputType: 'image' })}
            >
              + Image
            </Button>
          </Grid>
          <Grid item>
            <Button
              type="button"
              size="large"
              variant="outlined"
              onClick={() =>
                append({
                  type: 'data',
                  property: 'Source Text',
                  inputType: 'position',
                })
              }
            >
              + Position
            </Button>
          </Grid>
          <Grid item>
            <Button
              type="button"
              size="large"
              variant="outlined"
              onClick={() =>
                append({
                  type: 'data',
                  property: 'Source Text',
                  inputType: 'color',
                })
              }
            >
              + Color
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default InnerFields;
