import fire from 'fire';
import firebase from 'firebase';
import React, { createContext, useContext, useEffect, useState } from 'react';
import Loader from 'app/components/ui/general/Loader/Loader';

type User = firebase.User;

interface Organization {
  orgId: string;
  orgName: string;
  isAdmin: boolean;
}

interface AuthContextProps {
  loggedIn: boolean;
  user: (User & Organization) | null;
  setUser: Function | null;
}

export const AuthContext = createContext<AuthContextProps>({
  loggedIn: false,
  user: null,
  setUser: null,
});

export const useAuthContext = () => useContext(AuthContext);

export default function AuthProvider({ children }) {
  const [user, setUser] = useState<(User & Organization) | null>(null);
  const [loggedIn, setLoggedIn] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const unsubscribe = fire.auth().onAuthStateChanged(user => {
      setLoading(true);
      if (user) {
        fire
          .firestore()
          .collection(`users`)
          .doc(user.uid)
          .get()
          .then(doc => {
            if (!doc.get('adflowAdmin')) {
              fire.auth().signOut();
            }
            setUser({
              ...user,
              orgId: doc.get('orgId'),
              orgName: doc.get('orgName') || 'Ingen',
              isAdmin: doc.get('admin') || false,
            });
            setLoggedIn(true);
            setLoading(false);
          });
      } else {
        setUser(null);
        setLoggedIn(false);
        setLoading(false);
      }
    });

    return () => {
      unsubscribe();
    };
  }, []);

  if (loading) {
    return <Loader message="Loggar in" />;
  }

  return (
    <AuthContext.Provider value={{ user, setUser, loggedIn }}>
      {children}
    </AuthContext.Provider>
  );
}
