import React, { Component } from 'react';
import styled from 'styled-components';

interface ErrorBoundaryState {
  hasError: boolean;
  errorMessage: string;
}

export default class ErrorBoundary extends Component<{}, ErrorBoundaryState> {
  constructor(props) {
    super(props);
    this.state = { hasError: false, errorMessage: '' };
  }

  static getDerivedStateFromError(error) {
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    this.setState({ errorMessage: error });
  }

  render() {
    if (this.state.hasError) {
      return (
        <Container>
          {/* <img src={AdflowLogo} alt="adflow" /> */}
          <h1>Något har gått väldigt snett.</h1>
          <p>Om samma problem fortsätter uppstå, kontakta vår support</p>
          {/* eslint-disable-next-line no-restricted-globals*/}
          <button onClick={() => location.reload()}>Ladda om sidan</button>

          <pre>{this.state.errorMessage.toString()}</pre>
        </Container>
      );
    }

    return this.props.children;
  }
}

const Container = styled.div`
  height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 10%;
  text-align: center;

  p {
    margin: 2rem 0;
  }

  pre {
    margin-top: 2rem;
    color: red;
  }
`;
