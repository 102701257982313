import { Container as MuiContainer, Grid } from '@mui/material';
import fire from 'fire';
import { useEffect, useState } from 'react';
import { useRouteMatch } from 'react-router-dom';
import styled from 'styled-components';

const User = () => {
  const {
    params: { id },
  } = useRouteMatch<{ id: string }>();

  const [data, setData] = useState({});

  useEffect(() => {
    fire
      .firestore()
      .collection('users')
      .doc(id)
      .get()
      .then(doc => setData(doc.data()));
  }, [id]);

  return (
    <MuiContainer>
      <Container>
        <Grid container spacing={1}>
          {Object.entries(data)
            .sort((a, b) => a[0].localeCompare(b[0]))
            .map(item => {
              const [key, value] = item;

              return (
                <Grid xs={12} key={key} item>
                  <span style={{ fontWeight: 'bold' }}>{key}: </span>
                  <span>{value.toString()}</span>
                </Grid>
              );
            })}
        </Grid>
      </Container>
    </MuiContainer>
  );
};

export default User;

const Container = styled(MuiContainer)`
  background: ${p => p.theme.secondary};
  padding: 1rem;
  background: ${p => p.theme.secondary};
  box-shadow: 0 5px 20px 0 rgb(0 0 0 / 10%);
  border-radius: 15px;
`;
