import {
  DESKTOP_BREAKPOINT,
  MOBILE_BREAKPOINT,
  TABLET_BREAKPOINT,
} from '../consts/breakpoints';

export const theme = {
  primary: '#004cfe',
  lightPrimary: '#d9e4ff',
  secondary: '#fff',
  background: '#f9f9f9',
  error: 'red',
  primaryText: '#000',
  secondaryText: '#929292',
  borderColor: '#efefef',
  dividerColor: '#e6e6e6',
  filterHover: '#edf1fa',

  // Device widths
  deviceWidthSmall: `${MOBILE_BREAKPOINT}px`,
  deviceWidthMedium: `${TABLET_BREAKPOINT}px`,
  deviceWidthLarge: `${DESKTOP_BREAKPOINT}px`,
};
