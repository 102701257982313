const errorObject = {
  'permission-denied':
    'Du har inte tillräckliga rättigheter för detta konto eller har ingen kopplad organisation',
  'not-found':
    'Dokumentet du försöker ändra i verkar inte finnas. Kontakta Adflow support',
  default: 'Något gick fel. Kontakta Adflow support',
};

export const getErrorMessage = (code: string) => {
  switch (code) {
    case 'permission-denied':
      return errorObject['permission-denied'];
    case 'not-found':
      return errorObject['not-found'];
    default:
      return errorObject.default;
  }
};
