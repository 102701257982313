import { useState, ReactNode, useContext, forwardRef } from 'react';
import { Paths } from 'consts/router';
import { Link } from 'react-router-dom';
import {
  Button,
  Container,
  Snackbar,
  Alert as MuiAlert,
  AlertProps,
} from '@mui/material';

import styled from 'styled-components';
import fire from 'fire';
import { useAuthContext } from 'app/auth/AuthProvider';
import { createContext } from 'react';

interface LayoutProps {
  children: ReactNode;
}

const SnackbarContext = createContext<any>({});

type AddSnackArgs = {
  message: string;
  severity: 'error' | 'info' | 'success' | 'warning';
};

export const useSnackbarContext = () => {
  const { setOpen, setSnackbarMessage, setSeverity } =
    useContext(SnackbarContext);

  const addSnack = ({ message, severity }: AddSnackArgs) => {
    setOpen(true);
    setSnackbarMessage(message);
    setSeverity(severity);
  };

  return { addSnack };
};

const Alert = forwardRef<HTMLDivElement, AlertProps>(function Alert(
  props,
  ref,
) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const Layout = ({ children }: LayoutProps) => {
  const { loggedIn } = useAuthContext();
  const [open, setOpen] = useState(false);
  const [severity, setSeverity] = useState<
    'error' | 'info' | 'success' | 'warning'
  >('success');
  const [snackbarMessage, setSnackbarMessage] = useState('');

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };

  return (
    <Container>
      {loggedIn && (
        <Container>
          <Header>
            <ButtonWrap>
              <StyledLink to={Paths.USERS}>
                <Button variant="contained" color="primary">
                  Users
                </Button>
              </StyledLink>
              <StyledLink to={Paths.ORGANIZATIONS}>
                <Button variant="contained" color="primary">
                  Organizations
                </Button>
              </StyledLink>
              <StyledLink to={Paths.TEMPLATES}>
                <Button variant="contained" color="primary">
                  Templates
                </Button>
              </StyledLink>
              <StyledLink to={Paths.LOGS}>
                <Button variant="contained" color="primary">
                  Logs
                </Button>
              </StyledLink>
            </ButtonWrap>

            <SignOutButton
              variant="contained"
              color="secondary"
              onClick={() => fire.auth().signOut()}
            >
              Sign out
            </SignOutButton>
          </Header>
        </Container>
      )}
      <Flex>
        <SnackbarContext.Provider
          value={{ setOpen, setSnackbarMessage, setSeverity }}
        >
          <InnerContainer>{children}</InnerContainer>
        </SnackbarContext.Provider>
      </Flex>
      <Snackbar
        anchorOrigin={{ horizontal: 'center', vertical: 'bottom' }}
        open={open}
        autoHideDuration={2500}
        onClose={handleClose}
      >
        <Alert severity={severity}>{snackbarMessage}</Alert>
      </Snackbar>
    </Container>
  );
};

export default Layout;

const ButtonWrap = styled.div`
  display: grid;
  grid-auto-flow: column;
  gap: 1rem;
`;

const Header = styled.header`
  display: flex;
  margin: 1rem 0;
  padding: 1rem;
  background: ${p => p.theme.secondary};
  box-shadow: 0 5px 20px 0 rgb(0 0 0 / 10%);
  border-radius: 15px;
`;

const StyledLink = styled(Link)`
  text-decoration: none;
`;

const SignOutButton = styled(Button)`
  margin-left: auto !important;
`;

const Flex = styled.div`
  display: flex;
  margin: 0 auto;
  min-width: 1000px;
  @media (max-width: ${p => p.theme.deviceWidthMedium}) {
    flex-direction: column;
  }
`;

const InnerContainer = styled.div`
  flex: 1;
`;
